import React from 'react'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { z } from 'zod'

import { useGetAllTransactionsV1TransactionsGet } from '~/api/transactions/transactions.gen'
import { TrasnactionTable } from '~/pages/transaction'

export const Route = createFileRoute('/_auth/transactions')({
  validateSearch: z.object({
    userid: z.string().optional(),
    sourcetype: z.string().optional(),
    offerid: z.string().optional(),
    page: z.number().default(1),
    limit: z.number().default(100),
  }),
  component: () => <TransactionsPage />,
})

function TransactionsPage() {
  const navigate = useNavigate()
  const searchParams = Route.useSearch()

  const {
    data: { items, total, has_next: hasNext, has_prev: hasPrev } = {
      items: [],
      total: 0,
      has_next: false,
      has_prev: false,
    },
    isFetching,
    error,
  } = useGetAllTransactionsV1TransactionsGet(
    {
      user_id: searchParams.userid || null,
      source_type: searchParams.sourcetype || null,
      offer_id: searchParams.offerid || null,
      page: searchParams.page,
      limit: searchParams.limit,
    },
    {
      query: {
        enabled:
          !!searchParams.userid ||
          !!searchParams.sourcetype ||
          !!searchParams.offerid,
        initialData: {
          items: [],
          total: 0,
          has_next: false,
          has_prev: false,
        },
      },
    },
  )

  const handleSubmit = (
    userid: string | undefined,
    sourcetype: string | undefined,
    offerid: string | undefined,
  ) => {
    navigate({
      to: '/transactions',
      search: (prev) => ({
        ...prev,
        userid,
        sourcetype,
        offerid,
        limit: searchParams.limit,
        page: searchParams.page,
      }),
    })
  }

  const setPageNumber = (page: number) => {
    navigate({
      to: '/transactions',
      search: (prev) => ({ ...prev, page, limit: searchParams.limit }),
    })
    // refetch();
  }

  const setLimit = (limit: number) => {
    navigate({
      to: '/transactions',
      search: (prev) => ({ ...prev, limit, page: 1 }),
    })
    // refetch();
  }

  return (
    <TrasnactionTable
      initUserId={searchParams.userid}
      initSourceType={searchParams.sourcetype}
      initOfferId={searchParams.offerid}
      pageNumber={searchParams.page}
      limit={searchParams.limit}
      handleSubmit={handleSubmit}
      setPageNumber={setPageNumber}
      setLimit={setLimit}
      items={items}
      hasNext={hasNext || false}
      hasPrev={hasPrev || false}
      total={total || 0}
      loading={isFetching}
      error={error}
    />
  )
}
