import React from 'react'
import { createFileRoute, Link } from '@tanstack/react-router'

import Tab from '@/tab'
import { buttonVariants } from '@/ui/button'
import { Card } from '@/ui/card'

import { OfferFilterStatus } from '~/api/model'
import { prepareBonusOfferEmpty, prepareOfferEmpty } from '~/helpers/offer'
import Rewards, { RewardSearch } from '~/pages/Marketplace/Rewards'
import { useMarketplace } from '~/providers/marketplace'

export const Route = createFileRoute('/_auth/$market/marketplace/')({
  validateSearch: (
    search: Record<string, string | OfferFilterStatus | string[]>,
  ): RewardSearch => ({
    search: (search.search as string) || '',
    status: (search.status as OfferFilterStatus) || OfferFilterStatus.Active,
    rewardType: (search.rewardType as string) || '',
    platform: (search.platform as string) || '',
    categories: search.categories as string[],
    tags: search.tags as string[],
  }),
  component: () => <MarketplacePage />,
})

const MarketplacePage = () => {
  const { market } = Route.useParams()
  const search = Route.useSearch()
  const navigate = Route.useNavigate()
  const { almostSoldOutRewards } = useMarketplace()

  return (
    <section className="flex flex-col gap-10 bg-background px-8 py-10">
      <section className="flex items-start justify-between">
        <div className="header-titles_wrapper gap-4">
          <h2 className="text-foreground">Marketplace - Rewards</h2>
          <p className="text-foreground">
            In here you will find a list of rewards. The ordering that you will
            see is only for older app versions and will not be used in the
            ordering of the new Marketplace
            <br />
            <i className="text-sm">
              Codes displays amount purchased and total uploaded coupons
            </i>
          </p>
        </div>
        <div className="flex gap-6">
          <Link
            to="/$market/marketplace/rewards/bonus/create"
            params={{ market }}
            search={prepareBonusOfferEmpty(market)}
            className={buttonVariants({ variant: 'outline' })}
          >
            Create bonus reward
          </Link>
          <Link
            to="/$market/marketplace/rewards/create"
            params={{ market }}
            search={prepareOfferEmpty(market)}
            className={buttonVariants({ variant: 'default' })}
          >
            Create reward
          </Link>
        </div>
      </section>
      <div>
        <div className="flex w-full gap-2">
          <Tab active to={`/${market}/marketplace`} title="Rewards" />
          <Tab
            active={false}
            to={`/${market}/marketplace/collections`}
            title="Collections"
          />
          <Tab
            active={false}
            to={`/${market}/marketplace/liveview`}
            title="Live View"
          />
          <Tab
            active={false}
            to={`/${market}/marketplace/soldout`}
            title={`Sold out | ${almostSoldOutRewards.length}`}
          />
        </div>
        <Card className="bg-card px-4 py-10 text-card-foreground">
          <Rewards navigate={navigate} search={search} />
        </Card>
      </div>
    </section>
  )
}
