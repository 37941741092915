import React, { ReactNode } from "react";
import { useEffect, useState } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";

import {
  getGetInviteLinkByIdV1InviteLinksInviteLinkIdGetSuspenseQueryOptions,
  useUpdateInviteLinkV1InviteLinksInviteLinkIdPatch,
} from "~/api/invite-links/invite-links.gen";
import { useGetAvailableCountriesV1MarketConfigAvailableCountriesGet } from "~/api/market-config/market-config.gen";
import { parseMarketCountries } from "~/helpers/country-list";
import { convertToUsableDate } from "~/helpers/date-formatting";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { CreateEditPage } from "~/oldComponents/layout/CreateEditLayout";
import { FormConfig, PageConfig } from "~/oldComponents/layout/types";

export const Route = createFileRoute("/_auth/invite-links/$inviteLinkId")({
  loader: ({ context: { queryClient }, params: { inviteLinkId } }) => {
    return queryClient.ensureQueryData(getGetInviteLinkByIdV1InviteLinksInviteLinkIdGetSuspenseQueryOptions(inviteLinkId));
  },
  component: () => <UpdateInviteLinkPage />,
});

function UpdateInviteLinkPage() {
  const navigate = useNavigate();

  const inviteLinkId = Route.useParams().inviteLinkId;

  const [exipreDate, setExipreDate] = useState<Date | null>(null);

  const { data: inviteLink, isFetching } = useSuspenseQuery(getGetInviteLinkByIdV1InviteLinksInviteLinkIdGetSuspenseQueryOptions(inviteLinkId));

  const { data: countries } = useGetAvailableCountriesV1MarketConfigAvailableCountriesGet({
    query: {
      initialData: [],
    },
  });

  useEffect(() => {
    if (inviteLink.expireDate) {
      setExipreDate(convertToUsableDate(inviteLink.expireDate));
    }
  }, [inviteLink]);

  const updateInviteLinkMutation = useUpdateInviteLinkV1InviteLinksInviteLinkIdPatch({
    mutation: {
      onSuccess: () => {
        toast("Invite link was successfully updated!");
        navigate({ to: "/invite-links" });
      },
      onError: (error: AxiosError) => {
        toast.error("Error", {
          description: parseFastAPIError(error) as ReactNode,
        });
      },
    },
  });

  const onSubmit = async (event: React.FormEvent | undefined) => {
    event?.preventDefault();
    await updateInviteLinkMutation.mutateAsync({
      inviteLinkId,
      data: inviteLink,
    });
  };

  const page: PageConfig = {
    title: "Edit an invite link",
    description: "Use this elements, if you want to show some hints or additional information",
  };

  const form: FormConfig = {
    fields: [
      {
        name: "name",
        label: "Name:",
        type: "text",
        helpText: "The name to identify the invite link.",
        value: inviteLink.name,
        change: (event: React.ChangeEvent<HTMLInputElement>) => (inviteLink.name = event.target.value),
        row: true,
        required: true,
      },
      {
        name: "reward",
        label: "Reward:",
        type: "number",
        helpText: "The points rewarded for inviting users",
        value: inviteLink.reward,
        change: (event: React.ChangeEvent<HTMLInputElement>) => (inviteLink.reward = +event.target.value),
        row: true,
        min: 0,
        required: true,
      },
      {
        name: "signUpOnly",
        label: "Sign up only:",
        helpText: "If selected, points could be received only by inviting new users.",
        type: "checkbox",
        change: () => (inviteLink.signUpOnly = !inviteLink.signUpOnly),
        value: inviteLink.signUpOnly,
      },
      {
        name: "amount",
        label: "Amount:",
        type: "number",
        helpText: "If specified, shows how many times link can be used. If not - unlimited.",
        value: inviteLink.amount,
        change: (e: React.ChangeEvent<HTMLInputElement>) => (inviteLink.amount = +e.target.value),
        row: true,
        min: 1,
      },
      {
        name: "country",
        label: "Country",
        type: "search-select",
        helpText: "Used for customization link preview in messengers.",
        change: (data: string) => (inviteLink.country = data),
        value: inviteLink.country,
        options: parseMarketCountries(countries),
        empty: "Everywhere",
        row: true,
      },
      {
        name: "expireDate",
        label: "Expire date:",
        helpText: "If specified, Date when invite link would be expired.",
        type: "datetime-picker",
        value: exipreDate,
        change: (date: Date) => {
          setExipreDate(date);
          inviteLink.expireDate = date.toISOString();
        },
        custom: true,
      },
    ],
    onSubmit,
    submitText: "Save",
    name: "edit-points-link",
  };

  return <CreateEditPage loading={isFetching} page={page} form={form} />;
}
