import React, { useCallback, memo } from "react";
import { createFileRoute, Link } from "@tanstack/react-router";

import { Icon } from "@/icon/icon";
import { buttonVariants } from "@/ui/button";
import { Card, CardContent } from "@/ui/card";

import { Route as RouteType } from "~/routes_old";
import { useMarketStore } from "~/store/market";

export const Route = createFileRoute("/_auth/dashboard")({
  component: DashboardPage,
});

function DashboardPage() {
  const market = useMarketStore((state) => state.market);

  return (
    <section className="flex flex-col gap-10 bg-background px-8 py-10">
      <section className="flex items-start justify-between gap-4">
        <div className="header-titles_wrapper gap-4">
          <h2 className="text-foreground">Dashboard</h2>
          <p className="max-w-50p text-foreground">Quick navigation</p>
        </div>
        <div className="flex flex-col items-end gap-2" />
      </section>
      <div>
        <section className="rounded-lg bg-card p-8 text-card-foreground">
          <Card>
            <CardContent className="flex justify-around gap-4 p-4">
              <Link
                to={"/$market/marketplace"}
                params={{ market: market || "SE" }}
                title="Offers"
                className={`${buttonVariants({ variant: "ghost", size: "giantIcon" })} text-center`}
              >
                <Icon icon="MarketPlace" className="h-32 w-32" />
              </Link>
              <Link
                to="/users"
                search={{ page: 1, limit: 100 }}
                title="User Search"
                className={`${buttonVariants({ variant: "ghost", size: "giantIcon" })} text-center`}
              >
                <Icon icon="Users" className="h-32 w-32" />
              </Link>
              <Link to="/markets" title="Markets config" className={`${buttonVariants({ variant: "ghost", size: "giantIcon" })} text-center`}>
                <Icon icon="Settings" className="h-32 w-32" />
              </Link>
            </CardContent>
          </Card>
        </section>
      </div>
    </section>
  );
}
