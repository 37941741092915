import React, { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import { createFileRoute, Link } from '@tanstack/react-router'
import { AxiosError } from 'axios'
import { toast } from 'sonner'

import Tab from '@/tab'
import { Button, buttonVariants } from '@/ui/button'
import { Card } from '@/ui/card'

import {
  useCreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPost,
  useCreateUpdateDeleteRecommendedV1MarketplaceRecommendedPost,
  useReorderCategoriesV1MarketplaceCategoriesReorderPut,
} from '~/api/marketplace/marketplace.gen'
import { RecommendedModel } from '~/api/model'
import { prepareBonusOfferEmpty, prepareOfferEmpty } from '~/helpers/offer'
import { parseFastAPIError } from '~/helpers/parse-errors'
import { RecommendedList } from '~/pages/Marketplace/CollectionsParts/types'
import CategoriesPart from '~/pages/Marketplace/LiveViewParts/Categories'
import HighlightedCollectionsPart from '~/pages/Marketplace/LiveViewParts/HighlighedCollections'
import RecommendedPart from '~/pages/Marketplace/LiveViewParts/Recommended'
import {
  CategoryWithStatusAndRewardCount,
  HighlightedCollectionWithCollection,
  useMarketplace,
} from '~/providers/marketplace'

export const Route = createFileRoute('/_auth/$market/marketplace/liveview')({
  component: () => <MarketplacePage />,
})

function MarketplacePage() {
  const { market } = Route.useParams()
  const {
    recommended,
    categories,
    collections,
    refetchMarketplace,
    almostSoldOutRewards,
  } = useMarketplace()

  const [recommendedState, setRecommendedState] = useState<RecommendedList>([
    recommended.find((r: RecommendedModel) => r.order === 1) || undefined,
    recommended.find((r: RecommendedModel) => r.order === 2) || undefined,
    recommended.find((r: RecommendedModel) => r.order === 3) || undefined,
    recommended.find((r: RecommendedModel) => r.order === 4) || undefined,
    recommended.find((r: RecommendedModel) => r.order === 5) || undefined,
  ])
  const [categoriesState, setCategoriesState] = useState<
    CategoryWithStatusAndRewardCount[]
  >([])
  const [mappedCollections, setMappedCollecitons] = useState<
    HighlightedCollectionWithCollection[]
  >([])

  useEffect(() => {
    setRecommendedState([
      recommended.find((r: RecommendedModel) => r.order === 1) || undefined,
      recommended.find((r: RecommendedModel) => r.order === 2) || undefined,
      recommended.find((r: RecommendedModel) => r.order === 3) || undefined,
      recommended.find((r: RecommendedModel) => r.order === 4) || undefined,
      recommended.find((r: RecommendedModel) => r.order === 5) || undefined,
    ])
  }, [recommended])

  useEffect(() => {
    setCategoriesState(
      categories.sort(
        (
          a: CategoryWithStatusAndRewardCount,
          b: CategoryWithStatusAndRewardCount,
        ) => (a.order < b.order ? -1 : 1),
      ),
    )
  }, [categories, market])

  useEffect(() => {
    if (collections) {
      const sortedCollections = collections.sort((a, b) =>
        a.order > b.order ? 1 : -1,
      )
      setMappedCollecitons(sortedCollections)
    }
  }, [collections])

  const reorderCategoriesMutation =
    useReorderCategoriesV1MarketplaceCategoriesReorderPut({
      mutation: {
        onSuccess: () => {
          toast('Categories order was successfully updated!')
        },
        onSettled: () => {
          refetchMarketplace()
        },
      },
    })

  const highlightedCollectionOrderMutation =
    useCreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPost(
      {
        mutation: {
          onSuccess: () => {
            toast('Highlighted collections were successfully updated!')
          },
          onError: (error: AxiosError) => {
            toast.error('Error', {
              description: parseFastAPIError(error) as ReactNode,
            })
          },
          onSettled: () => {
            refetchMarketplace()
          },
        },
      },
    )

  const recommendedMutation =
    useCreateUpdateDeleteRecommendedV1MarketplaceRecommendedPost({
      mutation: {
        onSuccess: () => {
          toast('Recommended items were successfully updated!')
        },
        onError: (error: AxiosError) => {
          toast.error('Error', {
            description: parseFastAPIError(error) as ReactNode,
          })
        },
        onSettled: () => {
          refetchMarketplace()
        },
      },
    })

  function cancelChanges() {
    setRecommendedState([
      recommended.find((r: RecommendedModel) => r.order === 1) || undefined,
      recommended.find((r: RecommendedModel) => r.order === 2) || undefined,
      recommended.find((r: RecommendedModel) => r.order === 3) || undefined,
      recommended.find((r: RecommendedModel) => r.order === 4) || undefined,
      recommended.find((r: RecommendedModel) => r.order === 5) || undefined,
    ])
    setCategoriesState(
      categories.sort(
        (
          a: CategoryWithStatusAndRewardCount,
          b: CategoryWithStatusAndRewardCount,
        ) => (a.order < b.order ? -1 : 1),
      ),
    )
    if (collections) {
      const sortedCollections = collections.sort((a, b) =>
        a.order > b.order ? 1 : -1,
      )
      setMappedCollecitons(sortedCollections)
    }
  }

  function submitChanges() {
    recommendedMutation.mutate({
      data: recommendedState as RecommendedModel[],
      params: { market },
    })
    reorderCategoriesMutation.mutate({
      data: categoriesState.map(
        (category) => category._id?.toString() as string,
      ),
    })
    highlightedCollectionOrderMutation.mutate({
      params: { market },
      data: mappedCollections.map((collection) => ({
        _id: collection._id,
        data: collection.data,
        market: collection.market,
        order: collection.order,
        type: collection.type,
      })),
    })
  }

  return (
    <section className="flex flex-col gap-10 bg-background px-8 py-10">
      <section className="flex items-start justify-between">
        <div className="header-titles_wrapper gap-4">
          <h2 className="text-foreground">Marketplace - Live View</h2>
          <p className="text-foreground">
            In here you can edit Recommended, category ordering and highlighted
            collections.
          </p>
        </div>
        <div className="flex gap-6">
          <Link
            to="/$market/marketplace/rewards/bonus/create"
            params={{ market }}
            search={prepareBonusOfferEmpty(market)}
            className={buttonVariants({ variant: 'outline' })}
          >
            Create bonus reward
          </Link>
          <Link
            to="/$market/marketplace/rewards/create"
            params={{ market }}
            search={prepareOfferEmpty(market)}
            className={buttonVariants({ variant: 'default' })}
          >
            Create reward
          </Link>
        </div>
      </section>
      <div>
        <div className="flex w-full gap-2">
          <Tab active={false} to={`/${market}/marketplace`} title="Rewards" />
          <Tab
            active={false}
            to={`/${market}/marketplace/collections`}
            title="Collections"
          />
          <Tab
            active
            to={`/${market}/marketplace/liveview`}
            title="Live View"
          />
          <Tab
            active={false}
            to={`/${market}/marketplace/soldout`}
            title={`Sold out | ${almostSoldOutRewards.length}`}
          />
        </div>
        <Card className="bg-card px-4 py-10 text-card-foreground">
          <div className="flex flex-col gap-[72px]">
            <RecommendedPart
              recommended={recommendedState}
              updateRecommended={setRecommendedState}
            />
            <CategoriesPart
              categories={categoriesState}
              updateCategories={setCategoriesState}
            />
            <HighlightedCollectionsPart
              mappedCollections={mappedCollections}
              setMappedCollecitons={setMappedCollecitons}
            />
          </div>
        </Card>
        <div className="mt-10 flex justify-end gap-4">
          <Button variant="outline" onClick={() => cancelChanges()}>
            Cancel
          </Button>
          <Button onClick={() => submitChanges()}>Publish</Button>
        </div>
      </div>
    </section>
  )
}
