import React from "react";
import { memo } from "react";
import { UseNavigateResult } from "@tanstack/react-router";
import { Table } from "@tanstack/react-table";

import MultiSelectFormField from "@/multi_select";
import { Button } from "@/ui/button";
import { Input } from "@/ui/input";
import { Select, SelectContent, SelectItem, SelectSeparator, SelectTrigger, SelectValue } from "@/ui/select";

import { RewardSearch } from "./Rewards";

import { OfferFilterStatus, OfferType } from "~/api/model";
import { getCategoryName } from "~/helpers/category";
import { OfferWithCategoriesAndTags, useMarketplace } from "~/providers/marketplace";

type RewardsFilterProps = {
  table: Table<OfferWithCategoriesAndTags>;
  search: RewardSearch;
  navigate: UseNavigateResult<"/$market/marketplace/">;
  onStatusChange?: (value: string) => void;
};

// TODO: Fix the any props
export const RewardsFilter = memo(({ table, search, navigate, onStatusChange }: RewardsFilterProps) => {
  const { categories, tags } = useMarketplace();
  return (
    <form className="flex w-full flex-col gap-10 rounded-lg bg-accent p-4">
      <Input
        placeholder="Search"
        value={(table.getColumn("fullTitle")?.getFilterValue() as string) ?? search.search}
        onChange={(event) => {
          table.getColumn("fullTitle")?.setFilterValue(event.target.value);
          navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, search: event.target.value }) });
        }}
      />
      <div className="grid w-full grid-cols-5 items-center gap-10">
        <Select
          key={`status-${(table.getColumn("status")?.getFilterValue() as string) ?? ""}`}
          onValueChange={(value) => {
            table.getColumn("status")?.setFilterValue(value);
            navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, status: value as OfferFilterStatus }) });
            if (onStatusChange) onStatusChange(value);
          }}
          defaultValue={(table.getColumn("status")?.getFilterValue() as string) ?? OfferFilterStatus.Active}
        >
          <SelectTrigger>
            <SelectValue placeholder="Status" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={OfferFilterStatus.Active}>Active</SelectItem>
            <SelectItem value={OfferFilterStatus.Published}>Published</SelectItem>
            <SelectItem value={OfferFilterStatus.Unpublished}>Unpublished</SelectItem>
            <SelectItem value={OfferFilterStatus.Archived}>Archived</SelectItem>
          </SelectContent>
        </Select>
        <Select
          key={`rewardType-${(table.getColumn("rewardType")?.getFilterValue() || "undefined") ?? search.rewardType}`}
          onValueChange={(value) => {
            table.getColumn("rewardType")?.setFilterValue(value);
            navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, rewardType: value }) });
          }}
          value={(table.getColumn("rewardType")?.getFilterValue() as string) ?? search.rewardType}
        >
          <SelectTrigger>
            <SelectValue placeholder="Reward type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={OfferType.Regular}>Rewards only</SelectItem>
            <SelectItem value={OfferType.Bonus}>Bonus Rewards only</SelectItem>
            <SelectSeparator />
            <Button
              className="w-full px-2"
              variant="secondary"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, rewardType: undefined }) });
                table.getColumn("rewardType")?.setFilterValue(undefined);
              }}
            >
              Clear
            </Button>
          </SelectContent>
        </Select>
        <Select
          key={`platform-${(table.getColumn("platformString")?.getFilterValue() as string) ?? search.platform}`}
          onValueChange={(value) => {
            table.getColumn("platformString")?.setFilterValue(value);
            navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, platform: value }) });
          }}
          value={(table.getColumn("platformString")?.getFilterValue() as string) ?? search.platform}
        >
          <SelectTrigger>
            <SelectValue placeholder="Platform" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="ios">iOS only</SelectItem>
            <SelectItem value="android">Android only</SelectItem>
            <SelectSeparator />
            <Button
              className="w-full px-2"
              variant="secondary"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, platform: undefined }) });
                table.getColumn("platformString")?.setFilterValue(undefined);
              }}
            >
              Clear
            </Button>
          </SelectContent>
        </Select>
        <MultiSelectFormField
          singleLine
          placeholder="Categories"
          options={categories.map((category) => ({ label: getCategoryName(category.category) || "", value: category.category }))}
          defaultValue={(table.getColumn("categories")?.getFilterValue() as string[] | undefined) ?? search.categories}
          onValueChange={(value) => {
            table.getColumn("categories")?.setFilterValue(value);
            navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, categories: value }) });
          }}
        />
        <MultiSelectFormField
          singleLine
          placeholder="Tags"
          options={tags.map((tag) => ({ label: tag.name, value: tag._id as string }))}
          defaultValue={(table.getColumn("tags")?.getFilterValue() as string[] | undefined) ?? search.tags}
          onValueChange={(value) => {
            table.getColumn("tags")?.setFilterValue(value);
            navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, tags: value }) });
          }}
        />
      </div>
    </form>
  );
});

RewardsFilter.displayName = "RewardsFilter";
