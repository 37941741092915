import React from 'react'
import { createFileRoute } from '@tanstack/react-router'
import { toast } from 'sonner'

import { PointLinkModel } from '~/api/model'
import { useGetPointLinksV1PointLinksGetSuspense } from '~/api/point-links/point-links.gen'
import Button from '~/oldComponents/button/Button'
import { ListPage } from '~/oldComponents/layout/ListPage'
import { PageConfig, TableConfig } from '~/oldComponents/layout/types'

export const Route = createFileRoute('/_auth/points-links/')({
  component: () => <PointsLinksPage />,
})

function PointsLinksPage() {
  const navigate = Route.useNavigate()
  const { data: pointsLinks, isFetching } =
    useGetPointLinksV1PointLinksGetSuspense()

  const onCopyLink = async (link: PointLinkModel) => {
    navigator.clipboard.writeText(link.deeplink as string)
    toast('Deeplink was copied to clipboard!')
  }

  const page: PageConfig = {
    title: 'Points Links',
    description:
      'These are links to provide points to users once when he/she opened the link.<br>These links do not contain any tracking of any kind. (e.g. installs, clicks, etc.)',
    addLink: '/points-links/create',
    addContent: 'Add a new Point Link',
    tableUpload: {
      fileName: 'points-links',
      data: pointsLinks,
    },
  }

  const table: TableConfig = {
    tableBuild: [
      {
        headerTitle: 'Name',
        format: (item: PointLinkModel) => item.name,
      },
      {
        headerTitle: 'Reward',
        format: (item: PointLinkModel) => item.reward,
      },
      {
        headerTitle: 'Claimed',
        format: (item: PointLinkModel) => item.claimed,
      },
      {
        headerTitle: 'Country',
        format: (item: PointLinkModel) =>
          item.country ? item.country : 'Everywhere',
      },
      {
        headerTitle: 'Deeplink',
        actionRow: true,
        format: (item: PointLinkModel) =>
          item.deeplink ? (
            <Button
              title="Copy deep link"
              icon="FaCopy"
              onClick={() => onCopyLink(item)}
            />
          ) : (
            '-'
          ),
      },
    ],
    objects: pointsLinks,
    link: (item: PointLinkModel) =>
      navigate({
        to: '/points-links/$linkId',
        params: { linkId: item._id as string },
      }),
    emptyMessage: 'There are no points links in the system yet.',
  }

  return <ListPage loading={isFetching} page={page} table={table} />
}
