"use client";
import React from "react";
import { ColumnDef, flexRender, Table as TSTable } from "@tanstack/react-table";

import { Button } from "@/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/ui/table";

import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "./ui/select";

import { UserModelOutput } from "~/api/model";
import { cn } from "~/lib/utils";
import { OfferWithCategoriesAndTags } from "~/providers/marketplace";
import { Code, ToUpload } from "~/routes/_auth/$market/marketplace/rewards/$rewardId/coupons";
import { ChallengeDisplay } from "~/types/challenges";

type Props = {
  table: TSTable<Code> | TSTable<ToUpload> | TSTable<OfferWithCategoriesAndTags> | TSTable<UserModelOutput> | TSTable<ChallengeDisplay>;
  columns:
    | ColumnDef<Code>[]
    | ColumnDef<ToUpload>[]
    | ColumnDef<OfferWithCategoriesAndTags>[]
    | ColumnDef<UserModelOutput>[]
    | ColumnDef<ChallengeDisplay>[];
  hidePagination?: boolean;
  className?: string;
  loading?: boolean;
  emptyState?: string;
};

export function DataTable({ table, columns, hidePagination, className, loading, emptyState }: Props) {
  const renderTableRows = () => {
    if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length} className="h-24 text-center">
            Loading...
          </TableCell>
        </TableRow>
      );
    }

    if (table.getRowModel().rows?.length) {
      return table.getRowModel().rows.map((row) => (
        <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
          {row.getVisibleCells().map((cell) => (
            // @ts-expect-error this should work.
            <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
          ))}
        </TableRow>
      ));
    }

    return (
      <TableRow>
        <TableCell colSpan={columns.length} className="h-24 text-center">
          {emptyState || "No results."}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div className={cn("w-full", className)}>
      <div className="rounded-md">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="">
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {/* @ts-expect-error Headers not loaded correctly. VS code compains about removing it. But until the npm run type-check complainsit should stay */}
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            <TableRow className="border-0">
              <TableCell className="p-3" />
            </TableRow>
            {renderTableRows()}
          </TableBody>
        </Table>
      </div>
      {!hidePagination && (
        <div className="flex items-center justify-end space-x-2 py-4">
          <div className="flex-1 text-sm text-muted-foreground">
            <Select onValueChange={(value) => table.setPageSize(+value)} defaultValue="100">
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Set a page size" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Page size</SelectLabel>
                  <SelectItem value="1">1</SelectItem>
                  <SelectItem value="10">10</SelectItem>
                  <SelectItem value="100">100</SelectItem>
                  <SelectItem value="200">200</SelectItem>
                  <SelectItem value="500">500</SelectItem>
                  <SelectItem value="1000">1000</SelectItem>
                  <SelectItem value="1000000000">All</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>

          <div className="space-x-2">
            <Button variant="outline" size="sm" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
              Previous
            </Button>
            <Button variant="outline" size="sm" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
              Next
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
