import React from "react";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRouter, ErrorComponent, RouterProvider } from "@tanstack/react-router";
import { AxiosError } from "axios";

import { routeTree } from "./routeTree.gen";

import "./styles/reset.css";
import "./styles/index.css";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: (failureCount: number, error: Error) => {
        const status = error instanceof AxiosError ? error.response?.status : null;
        return Boolean(status && status > 500 && failureCount < 2);
      },
    },
  },
});

// Set up a Router instance
const router = createRouter({
  routeTree,
  // defaultPendingComponent: () => <SplashPage />,
  defaultErrorComponent: ({ error }) => <ErrorComponent error={error} />,
  context: {
    queryClient,
  },
  defaultPreload: false,
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
});

// Register things for typesafety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

function InnerApp() {
  const memoizedContext = React.useMemo(
    () => ({
      queryClient,
    }),
    [],
  );

  return <RouterProvider router={router} defaultPreload="intent" context={memoizedContext} />;
}

function App() {
  return <InnerApp />;
}

Sentry.init({
  dsn: import.meta.env.VITE_REACT_SENTRY_DSN,
  release: APP_VERSION,
  environment: import.meta.env.VITE_REACT_APP_NODE_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.feedbackIntegration({
      autoInject: import.meta.env.VITE_REACT_APP_NODE_ENV !== "production",
      showEmail: false,
      colorScheme: "system",
    }),
  ],
  tracesSampleRate: Number(import.meta.env.VITE_REACT_SENTRY_TRACES_SAMPLE_RATE) || 0,
  replaysSessionSampleRate: 0, // For now disable. Since we can only have 50 a month
  replaysOnErrorSampleRate: 0, // For now disable. Since we can only have 50 a month
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <Sentry.ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  </StrictMode>,
);
