import React from "react";
import { CSVLink } from "react-csv";
import { Link, ToPathOption } from "@tanstack/react-router";

import { flattenNestedObjects } from "../../helpers/object-transformer";
import Button from "../button/Button";
import Loader from "../loader/Loader";
import { Tabs } from "../tabs/Tabs";

import { DataOptions, TabsConfig } from "./types";

import "./pagelayout.css";

type Props = {
  title: string;
  description?: string;
  loading?: boolean;
  btnTitle?: string;
  addLink?: ToPathOption;
  addContent?: string;
  additionalLink?: ToPathOption;
  additionalAddContent?: string;
  onDeleteClick?: React.MouseEventHandler;
  onDeleteText?: string;
  additionalActions?: React.ReactNode;
  subHeader?: string;
  children?: React.ReactNode;
  readonly?: boolean;
  tableUpload?: {
    data: DataOptions;
    fileName: string;
    title?: string;
  };
  tabs?: TabsConfig;
};

const CommonPageLayout = ({
  loading,
  children,
  title,
  description,
  addLink,
  addContent,
  additionalLink,
  additionalAddContent,
  onDeleteClick,
  onDeleteText,
  subHeader,
  tableUpload,
  additionalActions,
  readonly,
  tabs,
}: Props) => {
  return (
    <>
      <section className="flex flex-col gap-10 bg-background px-8 py-10">
        <section className="flex items-start justify-between gap-4">
          <div className="header-titles_wrapper gap-4">
            <h2 className="text-foreground">{title}</h2>
            {description && <p className="max-w-50p text-foreground" dangerouslySetInnerHTML={{ __html: description }} />}
          </div>
          <div className="flex flex-col items-end gap-2">
            {additionalActions}
            {!!tableUpload?.data?.length && (
              <CSVLink className="button" data={flattenNestedObjects(tableUpload?.data)} target="_blank" filename={tableUpload?.fileName}>
                {tableUpload.title && tableUpload.title}
                {!tableUpload.title && "Download as CSV"}
              </CSVLink>
            )}
            {readonly}
            {addLink && !readonly && (
              <Link className="button" to={addLink} disabled={loading}>
                {addContent && addContent}
                {!addContent && "Add new entity"}
              </Link>
            )}
            {additionalLink && !readonly && (
              <Link className="button button--success" to={additionalLink} disabled={loading}>
                {additionalAddContent && additionalAddContent}
                {!additionalAddContent && "Add new entity"}
              </Link>
            )}
            {onDeleteClick && !readonly && (
              <Button onClick={onDeleteClick} disabled={loading} styleType="danger">
                {onDeleteText || "Delete"}
              </Button>
            )}
          </div>
        </section>
        <div>
          {tabs && tabs.tabs.length > 1 && <Tabs activeTab={tabs.activeTab} onClick={tabs.onClick} tabs={tabs.tabs} />}
          <section className="rounded-lg bg-card p-8 text-card-foreground">
            <div className="main-sub-headers">{subHeader && <h5 className="main-sub-headers_title">{subHeader}</h5>}</div>
            {children}
          </section>
        </div>
      </section>
      {loading && <Loader page />}
    </>
  );
};

export default CommonPageLayout;
