import React from "react";
import { useState } from "react";
import { Link } from "@tanstack/react-router";

import { ErrorType } from "~/api/base";
import { Error400Response, HTTPValidationError, SourceType, TransactionWithAdditionalData } from "~/api/model";
import formatDate from "~/helpers/date-formatting";
import { ListPage } from "~/oldComponents/layout/ListPage";
import { FormConfig, PageConfig, PaginationConfig, TableConfig } from "~/oldComponents/layout/types";

type Props = {
  staticUserId?: boolean;
  initUserId?: string;
  initSourceType?: string;
  initOfferId?: string;
  pageNumber: number;
  limit: number;
  handleSubmit: (userid: string | undefined, sourcetype: string | undefined, offerid: string | undefined) => void;
  setPageNumber: (page: number) => void;
  setLimit: (limit: number) => void;
  items: TransactionWithAdditionalData[];
  hasNext: boolean;
  hasPrev: boolean;
  total: number;
  loading: boolean;
  error: ErrorType<Error400Response | HTTPValidationError> | null;
};

export function TrasnactionTable({
  staticUserId,
  initUserId,
  initSourceType,
  initOfferId,
  pageNumber,
  limit,
  handleSubmit,
  setPageNumber,
  setLimit,
  items,
  hasNext,
  hasPrev,
  total,
  loading,
  error,
}: Props) {
  const [userid, setUserid] = useState<string | undefined>(initUserId);
  const [sourceType, setSourceType] = useState<string | undefined>(initSourceType);
  const [offerId, setOfferId] = useState<string | undefined>(initOfferId);

  function handle(event: React.FormEvent) {
    event?.preventDefault();
    handleSubmit(userid, sourceType, offerId);
  }

  const page: PageConfig = {
    title: "Transactions",
    description: staticUserId
      ? "Find all transactions of the user"
      : "Find all transactions in the system. Here you can search for a specific transaction",
    tableUpload: {
      fileName: "transactions",
      data: items,
    },
  };
  const form: FormConfig = {
    fields: [
      {
        name: "source-type",
        label: "Source type:",
        helpText: "Filter the type of transaction",
        type: "search-select",
        change: (data: string) => {
          setSourceType(data);
        },
        options: Object.keys(SourceType).map((key) => ({ name: key, value: key })),
        empty: "All",
        value: sourceType,
      },
      {
        name: "userid",
        label: "User ID:",
        helpText: "The ID of the user that the transaction is related to.",
        type: "text",
        disabled: staticUserId,
        change: (e: React.ChangeEvent<HTMLInputElement>) => {
          setUserid(e.target.value);
        },
        value: userid,
      },
      {
        name: "offer-id",
        label: "Offer ID:",
        helpText: "The ID of the offer that the transaction is related to. This will only include transactions that are related to an offer.",
        type: "text",
        change: (e: React.ChangeEvent<HTMLInputElement>) => {
          setOfferId(e.target.value);
        },
        value: offerId,
      },
    ],
    onSubmit: handle,
    submitText: "Search Transactions",
    name: "transaction-filter",
  };

  const table: TableConfig = {
    tableBuild: [
      {
        headerTitle: "User Id",
        format: (item: TransactionWithAdditionalData) => (
          <Link className="link" to={`/users/${item.transaction.userId}`} target="_blank">
            {item.transaction.userId}
          </Link>
        ),
      },
      { headerTitle: "Amount", format: (item: TransactionWithAdditionalData) => item.transaction.amount },
      { headerTitle: "Source type", format: (item: TransactionWithAdditionalData) => item.transaction.source.type },
      {
        headerTitle: "More info",
        format: (item: TransactionWithAdditionalData) => {
          if (item.order) {
            return (
              <>
                Offer:{" "}
                {item.order.offerSnapshot?.bonus ? (
                  <Link
                    to="/$market/marketplace/rewards/bonus/$rewardId"
                    params={{ market: item.order.offerSnapshot?.country || "SE", rewardId: item.order.offerId }}
                    target="_blank"
                    className="link"
                  >
                    {item.order.offerSnapshot?.titleV2 || "No title found"}
                  </Link>
                ) : (
                  <Link
                    to="/$market/marketplace/rewards/$rewardId"
                    params={{ market: item.order.offerSnapshot?.country || "SE", rewardId: item.order.offerId }}
                    target="_blank"
                    className="link"
                  >
                    {item.order.offerSnapshot?.titleV2 || "No title found"}
                  </Link>
                )}
                <br />
                Coupon: {item.coupon?.code || item.coupon?.url || ""}
              </>
            );
          } else if (item.step) {
            return `${item.step.trackedAmount?.toLocaleString() || 0} Steps`;
          } else if (item.admin) {
            return (
              <Link to={`/users/${item.admin._id}`} target="_blank" className="link">
                {item.admin.email || item.admin.profile?.email}
              </Link>
            );
          } else if (item.user_invite) {
            const isInviter = item.transaction.source.type === SourceType.UserInvite;
            const inviteCreatedByAdmin = !!item.user_invite?.inviteLinkId;
            const isInviteDeleted = !item.user_invite?.inviterId && !item.user_invite?.inviteeId && !item.user_invite?.inviteLinkId;

            const getInviteLabel = (isDeleted: boolean, isInviter: boolean) => {
              if (isDeleted) {
                return "-";
              }
              return isInviter ? "Invitee:" : "Inviter:";
            };

            return (
              <>
                {inviteCreatedByAdmin ? (
                  <>
                    <p>Invite link:</p>
                    <Link to={`/invite-links/${item.user_invite?.inviteLinkId}`} target="_blank" className="link">
                      {item.user_invite?.inviteLinkId}
                    </Link>
                  </>
                ) : (
                  <>
                    <p>{getInviteLabel(isInviteDeleted, isInviter)}</p>
                    <Link to={`/users/${isInviter ? item.user_invite?.inviteeId : item.user_invite?.inviterId}`} target="_blank" className="link">
                      {isInviter ? item.user_invite?.inviteeId : item.user_invite?.inviterId}
                    </Link>
                  </>
                )}
              </>
            );
          }

          return "-";
        },
      },
      { headerTitle: "Action date", format: (item: TransactionWithAdditionalData) => formatDate(item.transaction.actionDate || "") },
      { headerTitle: "Created at", format: (item: TransactionWithAdditionalData) => formatDate(item.transaction.createdAt || "") },
    ],
    objects: items,
  };

  const pagination: PaginationConfig = {
    page: {
      value: pageNumber,
      onChange: setPageNumber,
    },
    limit: {
      value: limit,
      onChange: setLimit,
    },
    hasNext,
    hasPrevious: hasPrev,
    total,
  };

  return <ListPage page={page} table={table} form={form} pagination={pagination} loading={loading} error={error} />;
}
