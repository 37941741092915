import React, { ReactNode } from "react";
import { memo, useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useForm, useWatch } from "react-hook-form";
import {
  Active,
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  Over,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { horizontalListSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import { zodResolver } from "@hookform/resolvers/zod";
import { QueryObserverResult, useQueryClient } from "@tanstack/react-query";
import { Link, useBlocker, useNavigate, useParams } from "@tanstack/react-router";
import MDEditor from "@uiw/react-md-editor/nohighlight";
import { AxiosError } from "axios";
import { addMonths, formatISO, subMinutes } from "date-fns";
import { toast } from "sonner";
import { z } from "zod";

import { Combobox, ComboboxOption } from "@/combobox";
import { DatePicker } from "@/datepicker";
import { Icon } from "@/icon/icon";
import ImageUploader from "@/image_upload";
import MultiSelectFormField from "@/multi_select";
import SortableImagePreviewUploader from "@/sortable_image_preview_upload";
import { Button, buttonVariants } from "@/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/ui/card";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/ui/form";
import { Input } from "@/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/ui/select";
import { Switch } from "@/ui/switch";

import { OfferPreview } from "./OfferPreview";

import { ErrorType } from "~/api/base";
import { useGetAvailableCountriesV1MarketConfigAvailableCountriesGet } from "~/api/market-config/market-config.gen";
import { useCreateTagV1MarketplaceTagsPost } from "~/api/marketplace/marketplace.gen";
import { Error400Response, HTTPValidationError, ImageType, OfferInput, OfferModel, OfferRelation, OfferStatus, OfferType } from "~/api/model";
import { useCreateOfferV1OffersPost, useUpdateOfferV1OffersOfferIdPut } from "~/api/offers/offers.gen";
import { getCategoryName } from "~/helpers/category";
import { getCountryName, parseMarketCountries } from "~/helpers/country-list";
import formatDate from "~/helpers/date-formatting";
import { prepareOfferDuplication } from "~/helpers/offer";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { cn } from "~/lib/utils";
import { OfferWithCategoriesAndTags, TagWithStatusAndRewardCount, useMarketplace } from "~/providers/marketplace";

const formSchema = z
  .object({
    type: z.enum([OfferType.Regular, OfferType.NewUserSpecialOffer, OfferType.Bonus]),
    price: z.number().min(1).catch(1),
    subTitle: z.string().min(1).max(50),
    companyDescription: z.string().max(1500),
    shortDescription: z.string().min(1).max(200),
    url: z.union([z.literal(""), z.string().trim().url()]).catch(""),
    country: z.string(),
    categories: z.array(z.string()).min(1),
    tags: z.array(z.string()),
    steplerOnly: z.boolean(),
    containsSubscription: z.boolean(),
    titleV2: z.string().min(1).max(25),
    longDescriptionV2: z.string().min(1).max(3000),
    amount: z.number().min(1),
    order: z.number().min(1).catch(1),
    purchaseCount: z.optional(z.number().min(0)),
    publishDate: z.date(),
    unPublishDate: z.date(),
    qty: z.string().optional(),
    shipping: z.string().optional(),
    valid: z.string().optional(),
    other: z.string().optional(),
    value: z.string().optional(),
    ios: z.boolean(),
    android: z.boolean(),
    status: z.enum([OfferStatus.Published, OfferStatus.Unpublished, OfferStatus.Archived]),
    hideInList: z.boolean(),
    relation: z.enum([OfferRelation.Bought_offer, OfferRelation.Partner_offer, OfferRelation.Stepler_shop, OfferRelation.no_CPO]),
    logoUrl: z.union([z.string().min(1, "Please upload a logo"), z.null()]),
    thumbnailUrl: z.string().min(1, "Please upload a thumbnail"),
    bannerUrl1: z.nullable(z.string()),
    bannerUrl2: z.nullable(z.string()),
    bannerUrl3: z.nullable(z.string()),
  })
  .refine((data) => data.unPublishDate > data.publishDate, {
    message: "Unpublish date cannot be earlier than publish date.",
    path: ["unPublishDate"],
  })
  .superRefine(({ status, publishDate, unPublishDate }, ctx) => {
    const currentDate = new Date();
    if (status === OfferStatus.Published) {
      if (publishDate > currentDate || unPublishDate < currentDate) {
        if (publishDate > currentDate) {
          ctx.addIssue({
            code: z.ZodIssueCode.too_big,
            maximum: 9,
            type: "string",
            inclusive: true,
            message: `Note: if you want the reward to be published, the date should be before ${formatDate(new Date())}`,
            path: ["publishDate"],
          });
        }
        if (unPublishDate < currentDate) {
          ctx.addIssue({
            code: z.ZodIssueCode.too_big,
            maximum: 9,
            type: "string",
            inclusive: true,
            message: `Note: if you want the reward to be published, the date should be after ${formatDate(new Date())}`,
            path: ["unPublishDate"],
          });
        }
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          maximum: 9,
          type: "string",
          inclusive: true,
          message:
            "Note: If the reward needs to be published in the future, update the status to unpublished. If you want the reward to be unpublished, update the status to unpublished",
          path: ["status"],
        });
      }
    } else if (status === OfferStatus.Unpublished) {
      if (publishDate < currentDate && unPublishDate > currentDate) {
        if (publishDate < currentDate) {
          ctx.addIssue({
            code: z.ZodIssueCode.too_big,
            maximum: 9,
            type: "string",
            inclusive: true,
            message: `Note: if you want to schedule the reward to be published in the futute, the date should be after ${formatDate(new Date())}`,
            path: ["publishDate"],
          });
        }
        if (unPublishDate > currentDate) {
          ctx.addIssue({
            code: z.ZodIssueCode.too_big,
            maximum: 9,
            type: "string",
            inclusive: true,
            message: `Note: if you want to unpublish the reward, the date should be before ${formatDate(new Date())}`,
            path: ["unPublishDate"],
          });
        }
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          maximum: 9,
          type: "string",
          inclusive: true,
          message: "Note: If the reward should be published, please change the status to published",
          path: ["status"],
        });
      }
    }
  });

export type OfferEdit = z.infer<typeof formSchema> & { _id?: string };

export type ExtendedOfferEdit = OfferEdit & { type: "Regular" | "Bonus" | "NewUserSpecialOffer" };

type OfferFormProps = {
  offer: OfferEdit;
  offerModel?: OfferWithCategoriesAndTags;
  refetch?: () => Promise<QueryObserverResult<OfferModel, ErrorType<Error400Response | HTTPValidationError>>>;
};

const OfferForm = memo(({ offer, offerModel, refetch }: OfferFormProps) => {
  const params = useParams({ from: "/_auth/$market/marketplace" });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { categories, tags, refetchMarketplace, rewards } = useMarketplace();
  const { data: countries } = useGetAvailableCountriesV1MarketConfigAvailableCountriesGet({
    query: {
      initialData: [],
    },
  });
  const [oldPublishDate, setOldPublishDate] = useState<Date | null>(null);
  const [oldUnpublishDate, setOldUnpublishDate] = useState<Date | null>(null);

  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

  const [localTags, setLocalTags] = useState<TagWithStatusAndRewardCount[]>(tags);

  useEffect(() => {
    setLocalTags(tags);
  }, [tags]);

  const form = useForm<OfferEdit>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      type: offer.type || OfferType.Regular,
      price: offer.price,
      subTitle: offer.subTitle,
      companyDescription: offer.companyDescription,
      shortDescription: offer.shortDescription,
      url: offer.url,
      country: offer.country || params.market,
      categories: offer.categories,
      tags: offer.tags,
      steplerOnly: offer.steplerOnly || false,
      containsSubscription: offer.containsSubscription || false,
      titleV2: offer.titleV2 || "",
      longDescriptionV2: offer.longDescriptionV2 || "",
      amount: offer.amount,
      order: offer.order || 1,
      purchaseCount: offer.purchaseCount,
      publishDate: offer.publishDate,
      unPublishDate: offer.unPublishDate,
      qty: offer.qty || "",
      shipping: offer.shipping || "",
      valid: offer.valid || "",
      other: offer.other || "",
      value: offer.value || "",
      ios: offer.ios,
      android: offer.android,
      status: offer.status || "Unpublished",
      hideInList: offer.hideInList || false,
      relation: offer.relation || "Partner offer",
      logoUrl: offer.logoUrl || "",
      thumbnailUrl: offer.thumbnailUrl,
      bannerUrl1: offer.bannerUrl1,
      bannerUrl2: offer.bannerUrl2,
      bannerUrl3: offer.bannerUrl3,
    },
  });

  useBlocker({
    blockerFn: () => window.confirm("Are you sure you want to leave when there are unsaved changes?"),
    condition: form.formState.isDirty && !form.formState.isSubmitSuccessful,
  });

  const createRewardMutation = useCreateOfferV1OffersPost({
    mutation: {
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const updateRewardMutation = useUpdateOfferV1OffersOfferIdPut({
    mutation: {
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  async function navigateAfterSave(sumitterName: string, data: OfferModel) {
    if (data._id && sumitterName === "stay") {
      if (refetch) {
        await refetch();
      } else {
        navigate({
          to: "/$market/marketplace/rewards/$rewardId",
          params: { rewardId: data._id as string, market: data.country },
        });
      }
    } else {
      navigate({
        to: "/$market/marketplace",
        params: { market: data.country },
      });
    }
  }

  function onSubmit(values: z.infer<typeof formSchema>, event: React.BaseSyntheticEvent<object, HTMLFormElement, SubmitEvent> | undefined) {
    if (!event) return;
    event?.preventDefault();

    const currentDate = new Date();

    if (values.unPublishDate < values.publishDate) {
      form.setError(
        "unPublishDate",
        {
          type: "manual",
          message: "Unpublish date must be after publish date",
        },
        { shouldFocus: true },
      );
      return;
    }

    if (values.status === "Published") {
      if (values.publishDate > currentDate) {
        form.setError(
          "publishDate",
          {
            type: "manual",
            message: "Publish date must be in the past to publish the bonus reward",
          },
          { shouldFocus: true },
        );
        return;
      }
      if (values.unPublishDate < currentDate) {
        form.setError(
          "unPublishDate",
          {
            type: "manual",
            message: "Unpublish date must be in the future to publish the bonus reward",
          },
          { shouldFocus: true },
        );
        return;
      }
    }

    const { bannerUrl1, bannerUrl2, bannerUrl3, publishDate, unPublishDate, ...restValues } = values;
    const bannerUrls = [];
    if (bannerUrl1) bannerUrls.push(bannerUrl1);
    if (bannerUrl2) bannerUrls.push(bannerUrl2);
    if (bannerUrl3) bannerUrls.push(bannerUrl3);

    if (bannerUrls.length === 0) {
      form.setError(
        "bannerUrl1",
        {
          type: "manual",
          message: "At least one banner is required",
        },
        { shouldFocus: true },
      );
      return;
    }

    const reward: OfferInput = {
      ...(restValues as OfferInput),
      bannerUrls,
      publishDate: formatISO(publishDate),
      unPublishDate: formatISO(unPublishDate),
    };

    if (offer._id) {
      updateRewardMutation.mutate(
        {
          offerId: offer._id,
          data: reward,
        },
        {
          async onSuccess(data) {
            toast(`Reward ${data.subTitle} was successfully updated!`);
            setTimeout(async () => {
              queryClient.invalidateQueries({ queryKey: ["/v1/marketplace/"] });
              await refetchMarketplace();
              if (refetch) {
                await refetch();
              }
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              const name = (event.nativeEvent.submitter.name as string) || "";
              await navigateAfterSave(name, data);
            }, 300);
          },
        },
      );
    } else {
      createRewardMutation.mutate(
        {
          data: reward,
        },
        {
          async onSuccess(data) {
            toast(`Reward ${data.subTitle} was successfully created!`);
            setTimeout(async () => {
              queryClient.invalidateQueries({ queryKey: ["/v1/marketplace/"] });
              await refetchMarketplace();
              if (refetch) {
                await refetch();
              }
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              const name = (event.nativeEvent.submitter.name as string) || "";
              await navigateAfterSave(name, data);
            }, 300);
          },
        },
      );
    }
  }

  const formData = useWatch({
    control: form.control,
  });

  useEffect(() => {
    if (formData.thumbnailUrl) {
      form.clearErrors("thumbnailUrl");
    }
  }, [formData.thumbnailUrl, form]);

  useEffect(() => {
    if (formData.logoUrl) {
      form.clearErrors("logoUrl");
    }
  }, [formData.logoUrl, form]);

  useEffect(() => {
    if (formData.publishDate) {
      form.clearErrors("publishDate");
      form.clearErrors("unPublishDate");
      form.clearErrors("status");
    }
    if (oldPublishDate) {
      setOldPublishDate(null);
    }
  }, [formData.publishDate, form, oldPublishDate]);

  useEffect(() => {
    if (formData.unPublishDate) {
      form.clearErrors("publishDate");
      form.clearErrors("unPublishDate");
      form.clearErrors("status");
    }
    if (oldUnpublishDate) {
      setOldPublishDate(null);
    }
  }, [formData.unPublishDate, form, oldUnpublishDate]);

  useEffect(() => {
    const now = new Date();
    const oldPublishDate = formData.publishDate;
    const oldUnpublishDate = formData.unPublishDate;

    let updatePublished = false;
    let updateUnpublished = false;

    if (formData.status === OfferStatus.Published) {
      if (formData.publishDate && formData.publishDate > now) {
        form.setValue("publishDate", now);
        updatePublished = true;
      } else if (formData.unPublishDate && formData.unPublishDate < now) {
        form.setValue("unPublishDate", addMonths(now, 3));
        updateUnpublished = true;
      }
    } else if (formData.status === OfferStatus.Unpublished) {
      if (!formData.publishDate || !formData.unPublishDate) {
        return;
      } else if (formData.publishDate < now && formData.unPublishDate > now) {
        form.setValue("unPublishDate", subMinutes(now, 5));
        updateUnpublished = true;
      } else if (formData.publishDate > now && formData.unPublishDate < now) {
        form.setValue("publishDate", subMinutes(now, 5));
        updatePublished = true;
      }
    }
    if (updatePublished && oldPublishDate) {
      setTimeout(() => {
        setOldPublishDate(oldPublishDate);
      }, 200);
    }
    if (updateUnpublished && oldUnpublishDate) {
      setTimeout(() => {
        setOldUnpublishDate(oldUnpublishDate);
      }, 200);
    }
  }, [formData.status, form, formData.publishDate, formData.unPublishDate]);

  useEffect(() => {
    if (tags) {
      setLocalTags(
        tags.map((tag) => {
          return {
            ...tag,
            status: [],
            rewardCount: tag.rewardOrder?.length || 0,
            publishedCount: rewards.filter((reward) => reward.tagIds.includes(tag._id?.toString() || "") && reward.status === OfferStatus.Published)
              .length,
            unpublishedCount: rewards.filter(
              (reward) => reward.tagIds.includes(tag._id?.toString() || "") && reward.status === OfferStatus.Unpublished,
            ).length,
          } as TagWithStatusAndRewardCount;
        }),
      );
    }
  }, [tags, rewards]);

  const [showPreview, setShowPreview] = useState(localStorage.getItem("show-preview") === "true");
  const onPreviewToggle = () => setShowPreview(!showPreview);

  useEffect(() => {
    localStorage.setItem("show-preview", showPreview.toString());
  }, [showPreview]);

  function handleDragEnd(event: DragEndEvent) {
    const { active, over }: { active: Active; over: Over | null } = event;

    const old1 = formData.bannerUrl1 || null;
    const old2 = formData.bannerUrl2 || null;
    const old3 = formData.bannerUrl3 || null;

    if (active?.id && over?.id) {
      if ((active.id === "bannerUrl1" && over.id === "bannerUrl2") || (active.id === "bannerUrl2" && over.id === "bannerUrl1")) {
        form.setValue("bannerUrl1", old2);
        form.setValue("bannerUrl2", old1);
      } else if ((active.id === "bannerUrl1" && over.id === "bannerUrl3") || (active.id === "bannerUrl3" && over.id === "bannerUrl1")) {
        form.setValue("bannerUrl1", old3);
        form.setValue("bannerUrl3", old1);
      } else if ((active.id === "bannerUrl2" && over.id === "bannerUrl3") || (active.id === "bannerUrl3" && over.id === "bannerUrl2")) {
        form.setValue("bannerUrl2", old3);
        form.setValue("bannerUrl3", old2);
      }
    }
  }

  const createTagMutation = useCreateTagV1MarketplaceTagsPost();

  const getDefaultPurchaseCountValue = (value: number | undefined) => {
    if (value === undefined) return "unlimited";
    if (value === 0) return "0";
    return "1";
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <div className="grid grid-cols-[minmax(390px,_1fr)_390px] items-start gap-4">
          <div className="col-span-2 flex justify-between">
            {offerModel ? (
              <Button variant="input" size="input" className="w-80 cursor-not-allowed justify-between bg-card">
                <div className="flex gap-1.5">
                  <ReactCountryFlag countryCode={formData.country as string} svg />
                  {getCountryName(formData.country)}
                </div>
              </Button>
            ) : (
              <Combobox
                searchName="Country"
                placeholder="Country"
                options={parseMarketCountries(countries)}
                onChange={(value: ComboboxOption) => {
                  if (value.value !== form.getValues().country) {
                    form.setValue("tags", []);
                  }
                  form.setValue("country", value.value as string);
                  refetchMarketplace();
                }}
                value={formData.country}
                className="w-80 bg-card"
                showFlag
              />
            )}
            <div className="flex items-center gap-6">
              {offerModel && offerModel._id && (
                <>
                  <Link
                    to="/$market/marketplace/rewards/$rewardId/coupons"
                    params={{ rewardId: offerModel._id, market: offerModel.country }}
                    search={{ status: "Unused" }}
                    className={cn(buttonVariants({ variant: "outline" }), "flex items-center gap-2")}
                  >
                    <Icon icon="Codes" /> Codes
                  </Link>
                  <Link
                    to="/$market/marketplace/rewards/create"
                    params={{ market: offerModel.country }}
                    search={prepareOfferDuplication(offerModel)}
                    className={cn(buttonVariants({ variant: "outline" }), "flex items-center gap-2")}
                  >
                    <Icon icon="Duplicate" /> Duplicate
                  </Link>
                </>
              )}
              <Button onClick={onPreviewToggle} type="button" className="flex items-center gap-2">
                <Icon icon="Eye" /> {showPreview ? "hide Preview" : "show Preview"}
              </Button>
            </div>
          </div>
          <div className="grid grid-cols-1 items-start gap-4">
            <Card className="col-start-1">
              <CardHeader>
                <CardTitle>Reward Info</CardTitle>
              </CardHeader>
              <CardContent className="flex flex-col gap-10">
                <FormField
                  control={form.control}
                  name="type"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel className="flex justify-between">Reward Type</FormLabel>
                      <FormControl>
                        <Select
                          onValueChange={(value) => {
                            form.setValue("type", value as OfferType);
                          }}
                          defaultValue={field.value}
                        >
                          <SelectTrigger ref={field.ref}>
                            <SelectValue placeholder="Select a type" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value={OfferType.Regular}>{OfferType.Regular}</SelectItem>
                            <SelectItem value={OfferType.NewUserSpecialOffer}>{OfferType.NewUserSpecialOffer}</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="subTitle"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel className="flex justify-between">
                        Title
                        <span className="text-xs">Max characters: 50 / {field.value?.split("").length || 0}</span>
                      </FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} maxLength={50} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="shortDescription"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel className="flex justify-between">
                        Short Description
                        <span className="text-xs">Max characters: 200 / {field.value?.split("").length || 0}</span>
                      </FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} maxLength={200} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="longDescriptionV2"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel className="flex justify-between">
                        Description
                        <span className="text-xs">Max characters: 3000 / {field.value?.split("").length || 0}</span>
                      </FormLabel>
                      <FormControl>
                        <MDEditor {...field} preview="edit" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CardContent>
            </Card>
            <Card className="col-start-1">
              <CardHeader>
                <CardTitle>Company</CardTitle>
              </CardHeader>
              <CardContent className="flex flex-col gap-10">
                <FormField
                  control={form.control}
                  name="logoUrl"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel>Logo</FormLabel>
                      <FormControl>
                        <ImageUploader
                          imageType={ImageType.offer_logo}
                          onChange={(image: string) => form.setValue("logoUrl", image)}
                          defaultImage={formData.logoUrl || undefined}
                          ref={field.ref}
                        >
                          <img
                            src={formData.logoUrl ? `${import.meta.env.VITE_ASSETS_DOMAIN}/${formData.logoUrl}` : ""}
                            className="aspect-square h-10 w-10 rounded-full"
                          />
                        </ImageUploader>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="titleV2"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel className="flex justify-between">
                        Company name
                        <span className="text-xs">Max characters: 25 / {field.value?.split("").length || 0}</span>
                      </FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} maxLength={25} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="companyDescription"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel className="flex justify-between">
                        Company description
                        <span className="text-xs">Max characters: 1500 / {field.value?.split("").length || 0}</span>
                      </FormLabel>
                      <FormControl>
                        <MDEditor {...field} preview="edit" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="url"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel>Company Url</FormLabel>
                      <FormControl>
                        <Input placeholder="https://link-to-company.com" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CardContent>
            </Card>
            <Card className="col-start-1">
              <CardHeader>
                <CardTitle>Media</CardTitle>
              </CardHeader>
              <CardContent className="flex flex-col gap-10">
                <FormField
                  control={form.control}
                  name="thumbnailUrl"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel>Thumbnail</FormLabel>
                      <FormControl>
                        <ImageUploader
                          imageType={ImageType.offer_thumbnail}
                          onChange={(image: string) => form.setValue("thumbnailUrl", image)}
                          defaultImage={formData.thumbnailUrl}
                          ref={field.ref}
                        >
                          <Card className="w-[343px] rounded-lg shadow-md">
                            <img
                              src={formData.thumbnailUrl ? `${import.meta.env.VITE_ASSETS_DOMAIN}/${formData.thumbnailUrl}` : ""}
                              className="aspect-video w-[343px] rounded-t-lg"
                            />
                            <CardContent className="flex gap-3 px-4 py-6">
                              <img
                                src={formData.logoUrl ? `${import.meta.env.VITE_ASSETS_DOMAIN}/${formData.logoUrl}` : ""}
                                className="aspect-square h-10 w-10 rounded-full"
                              />
                              <div className="flex flex-col gap-1">
                                <h4>{formData.subTitle || "Title"}</h4>
                                <div className="text-sm">{formData.titleV2 || "Company name"}</div>
                              </div>
                            </CardContent>
                          </Card>
                        </ImageUploader>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="flex flex-col gap-2">
                  <div className="font-medium">Detail images</div>
                  <DndContext collisionDetection={closestCenter} modifiers={[restrictToHorizontalAxis]} onDragEnd={handleDragEnd} sensors={sensors}>
                    <div className="flex gap-4">
                      <SortableContext items={["bannerUrl1", "bannerUrl2", "bannerUrl3"]} strategy={horizontalListSortingStrategy}>
                        <FormField
                          control={form.control}
                          name="bannerUrl1"
                          render={({ field }) => (
                            <FormItem className="flex flex-col gap-2">
                              <FormControl>
                                <SortableImagePreviewUploader
                                  id="bannerUrl1"
                                  imageType={ImageType.offer_banner}
                                  onChange={(image: string | null) => form.setValue("bannerUrl1", image)}
                                  defaultImage={field.value}
                                  ref={field.ref}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="bannerUrl2"
                          render={({ field }) => (
                            <FormItem className="flex flex-col gap-2">
                              <FormControl>
                                <SortableImagePreviewUploader
                                  id="bannerUrl2"
                                  imageType={ImageType.offer_banner}
                                  onChange={(image: string | null) => form.setValue("bannerUrl2", image)}
                                  defaultImage={field.value}
                                  ref={field.ref}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="bannerUrl3"
                          render={({ field }) => (
                            <FormItem className="flex flex-col gap-2">
                              <FormControl>
                                <SortableImagePreviewUploader
                                  id="bannerUrl3"
                                  imageType={ImageType.offer_banner}
                                  onChange={(image: string | null) => form.setValue("bannerUrl3", image)}
                                  defaultImage={field.value}
                                  ref={field.ref}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </SortableContext>
                    </div>
                  </DndContext>
                </div>
              </CardContent>
            </Card>
            <Card className="col-start-1">
              <CardHeader>
                <CardTitle>Terms</CardTitle>
              </CardHeader>
              <CardContent className="grid grid-cols-2 gap-10">
                <FormField
                  control={form.control}
                  name="purchaseCount"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel>Number of claims allowed/user</FormLabel>
                      <FormControl>
                        <Select
                          onValueChange={(value) => {
                            if (value === "unlimited") {
                              form.setValue("purchaseCount", undefined);
                            } else {
                              form.setValue("purchaseCount", +value);
                            }
                          }}
                          defaultValue={getDefaultPurchaseCountValue(field.value)}
                        >
                          <SelectTrigger ref={field.ref}>
                            <SelectValue placeholder="Unlimited" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="unlimited" onClick={() => form.setValue("purchaseCount", undefined)}>
                              Unlimited
                            </SelectItem>
                            <SelectItem value="0">Unbuyable</SelectItem>
                            <SelectItem value="1">Once per user</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="qty"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel>Quantity</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="shipping"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel>Shipping</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="valid"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel>Valid</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="value"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel>Value</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="other"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel>Other</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="steplerOnly"
                  render={({ field }) => (
                    <FormItem className="flex gap-2">
                      <FormControl>
                        <Switch checked={field.value} onCheckedChange={(value) => field.onChange(value)} ref={field.ref} />
                      </FormControl>
                      <FormLabel>Exclusive</FormLabel>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="containsSubscription"
                  render={({ field }) => (
                    <FormItem className="flex gap-2">
                      <FormControl>
                        <Switch checked={field.value} onCheckedChange={(value) => field.onChange(value)} ref={field.ref} />
                      </FormControl>
                      <FormLabel>Contains subscription</FormLabel>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CardContent>
            </Card>
          </div>
          {showPreview && <OfferPreview formData={formData as ExtendedOfferEdit} />}
          <div className="grid grid-cols-1 items-start gap-y-4">
            <Card>
              <CardHeader>
                <CardTitle>Publishing</CardTitle>
              </CardHeader>
              <CardContent className="flex flex-col gap-10">
                <FormField
                  control={form.control}
                  name="status"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel>Status</FormLabel>
                      <Select onValueChange={field.onChange} defaultValue={field.value}>
                        <FormControl>
                          <SelectTrigger ref={field.ref}>
                            <SelectValue placeholder="Status" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="Unpublished">Unpublished</SelectItem>
                          <SelectItem value="Published">Published</SelectItem>
                          <SelectItem value="Archived">Archived</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="publishDate"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel>Publish date</FormLabel>
                      <FormControl>
                        <DatePicker
                          showHourPicker
                          defaultValue={field.value}
                          onChange={(date) => {
                            if (date) form.setValue("publishDate", date);
                          }}
                          ref={field.ref}
                        />
                      </FormControl>
                      {oldPublishDate && (
                        <FormDescription>This value was automatically changed from {formatDate(oldPublishDate) as ReactNode}</FormDescription>
                      )}
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="unPublishDate"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel>Unpublish date</FormLabel>
                      <FormControl>
                        <DatePicker
                          showHourPicker
                          defaultValue={field.value}
                          onChange={(date) => {
                            if (date) form.setValue("unPublishDate", date);
                          }}
                          ref={field.ref}
                        />
                      </FormControl>
                      {oldUnpublishDate && (
                        <FormDescription>This value was automatically changed from {formatDate(oldUnpublishDate) as ReactNode}</FormDescription>
                      )}
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="ios"
                  render={({ field }) => (
                    <FormItem className="flex gap-2">
                      <FormControl>
                        <Switch checked={field.value} onCheckedChange={(value) => field.onChange(value)} ref={field.ref} />
                      </FormControl>
                      <FormLabel>iOS</FormLabel>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="android"
                  render={({ field }) => (
                    <FormItem className="flex gap-2">
                      <FormControl>
                        <Switch checked={field.value} onCheckedChange={(value) => field.onChange(value)} ref={field.ref} />
                      </FormControl>
                      <FormLabel>Android</FormLabel>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="order"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel>Order</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Set order, otherwise placed at the end"
                          {...field}
                          type="number"
                          onChange={(e) => (e.target.value ? field.onChange(+e.target.value) : field.onChange(null))}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Product organization</CardTitle>
              </CardHeader>
              <CardContent className="flex flex-col gap-10">
                <FormField
                  control={form.control}
                  name="categories"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel>Category</FormLabel>
                      <FormControl>
                        <MultiSelectFormField
                          placeholder="Categories"
                          options={categories.map((category) => ({ label: getCategoryName(category.category) || "", value: category.category }))}
                          defaultValue={field.value}
                          onValueChange={field.onChange}
                          ref={field.ref}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="tags"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel>Tags</FormLabel>
                      <FormControl>
                        <MultiSelectFormField
                          placeholder="Tags"
                          options={localTags.map((tag) => ({ label: tag.name, value: tag._id?.toString() || "" }))}
                          defaultValue={field.value}
                          onValueChange={field.onChange}
                          searchOptions={{
                            emptyState: "add",
                            addEvent: (value) => {
                              createTagMutation.mutate(
                                { data: { name: value, market: formData.country as string } },
                                {
                                  onSuccess: (tag) => {
                                    setLocalTags([{ ...tag, status: [], rewardCount: 0 } as TagWithStatusAndRewardCount, ...localTags]);
                                    field.onChange([...field.value, tag._id || ""]);
                                  },
                                  onError: (error: AxiosError) => {
                                    toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
                                  },
                                },
                              );
                            },
                          }}
                          ref={field.ref}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="relation"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel>Relation</FormLabel>
                      <Select onValueChange={field.onChange} defaultValue={field.value}>
                        <FormControl>
                          <SelectTrigger ref={field.ref}>
                            <SelectValue placeholder="Select a relation" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="Partner offer">Partner offer</SelectItem>
                          <SelectItem value="Stepler shop">Stepler shop</SelectItem>
                          <SelectItem value="Bought offer">Bought offer</SelectItem>
                          <SelectItem value="no CPO">no CPO</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Points & Invertory</CardTitle>
              </CardHeader>
              <CardContent className="flex flex-col gap-10">
                <FormField
                  control={form.control}
                  name="price"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel>Points requirement</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} min={1} type="number" onChange={(e) => field.onChange(+e.target.value)} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="amount"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel>Available inventory</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} type="number" onChange={(e) => field.onChange(+e.target.value)} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormItem className="flex flex-col gap-2">
                  <FormLabel>Codes warning limit</FormLabel>
                  <FormControl>
                    <Input disabled value={offerModel?.couponWarningLimit || "7"} type="number" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              </CardContent>
            </Card>
          </div>
        </div>
        <div className="flex justify-end gap-6">
          <Button variant="outline" type="submit" name="stay">
            {offer._id ? "Update reward and stay on page" : "Create reward and edit"}
          </Button>
          <Button type="submit">{offer._id ? "Update reward" : "Create reward"}</Button>
        </div>
      </form>
    </Form>
  );
});
OfferForm.displayName = "OfferForm";

export default OfferForm;
