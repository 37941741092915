import React from "react";
import { ChangeEventHandler } from "react";
import ReactDatePicker from "react-datepicker";
import MDEditor, { commands } from "@uiw/react-md-editor/nohighlight";
import { enGB } from "date-fns/locale/en-GB";

import ImagePreviewUploader from "@/image_preview_upload";

import { Input } from "../input/Input";
import { SearchableSelect } from "../select/SearchSelect";
import { Select } from "../select/Select";

import { Checkbox } from "./Checkbox";
import { TextArray } from "./TextArray";
import { Field } from "./types";

import "react-datepicker/dist/react-datepicker.css";
import "./helpers.scss";

import { ImageType } from "~/api/model";

export function getTypedField(field: Field, overwrittenValue?: string, index?: number) {
  const value = overwrittenValue || field.value;

  if (field.type === "render" && field.render) {
    return field.render();
  } else if (field.type === "divider") {
    return <hr />;
  } else if (field.type === "datetime-picker") {
    return (
      <div className="width-100">
        <ReactDatePicker
          className="input bg-background text-foreground"
          id={field.name}
          showTimeSelect
          dateFormat="dd-MM-yyyy HH:mm"
          timeIntervals={60}
          locale={enGB}
          onChange={field.change as (date: Date | null, event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void}
          selected={value as Date | null | undefined}
          required={field.required}
          inline={field.inline}
          value={value as string | undefined}
          data-index={index}
          showWeekNumbers
        />
      </div>
    );
  } else if (field.type === "checkbox") {
    return (
      <Checkbox
        name={field.name}
        disabled={field.disabled}
        checked={value as boolean | undefined}
        onChange={field.change as ChangeEventHandler}
        data-index={index}
      />
    );
  } else if (field.type === "search-select") {
    return (
      <SearchableSelect
        name={field.name}
        value={value as string}
        empty={field.empty}
        optionList={field.options}
        change={field.change as (value?: string) => void}
        required={field.required}
        data-index={index}
        disabled={field.disabled}
      />
    );
  } else if (field.type === "select" && field.options) {
    return (
      <Select
        name={field.name}
        selectedValue={value as string | number}
        empty={field.empty}
        optionList={field.options}
        onChange={field.change as ChangeEventHandler}
        required={field.required}
        data-index={index}
        disabled={field.disabled}
      />
    );
  } else if (field.type === "text-editor") {
    return (
      <MDEditor
        preview="edit"
        value={value as string | undefined}
        onChange={field.change as (value?: string) => void}
        className="width-100"
        data-index={index}
        extraCommands={[commands.codeEdit, commands.codeLive]}
      />
    );
  } else if (field.type === "image-cropper") {
    return (
      <ImagePreviewUploader
        imageType={field.imageType as ImageType}
        onChange={field.change as (image: string | null) => void}
        defaultImage={value as string | null}
      />
    );
  } else if (field.type === "text-array") {
    return (
      <TextArray required={field?.required} textArray={value as string[]} change={field.change as (newArray: string[]) => void} data-index={index} />
    );
  } else {
    return (
      <Input
        name={field.name}
        type={field.type}
        onChange={field.change as ChangeEventHandler}
        value={value as string | number | undefined}
        className={field?.className || ""}
        required={field?.required}
        min={field?.min}
        max={field?.max}
        data-index={index}
        disabled={field.disabled}
      />
    );
  }
};
