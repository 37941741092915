import React, { useCallback, useEffect, useRef } from "react";
import { createFileRoute, useRouter } from "@tanstack/react-router";
import { jwtDecode } from "jwt-decode";
import { z } from "zod";

import SplashPage from "~/pages/Splash/Splash";
import { useAuthStore } from "~/store/authStore";
import { ExtendedJwtPayload } from "~/types/auth";

const fallback = "/dashboard" as const;

export const Route = createFileRoute("/setauth")({
  validateSearch: z.object({
    tokens: z.string().catch(""),
    additional: z.string().catch(""),
    next_url: z.string().optional().catch("/dashboard"),
  }),
  component: AuthPage,
});

function AuthPage() {
  const { setTokens, setUser } = useAuthStore();
  const router = useRouter();
  const navigate = Route.useNavigate();
  const search = Route.useSearch();
  const isAuthenticating = useRef(false);

  const handleAuth = useCallback(async () => {
    if (isAuthenticating.current) return;
    isAuthenticating.current = true;

    try {
      const decodedTokens = atob(search.tokens);
      const user = JSON.parse(atob(search.additional));
      const [accessToken, refreshToken] = decodedTokens.split("/");
      const next_url = search.next_url || fallback;

      setTokens({
        accessToken,
        refreshToken,
      });

      const { permissions }: ExtendedJwtPayload = jwtDecode(accessToken);
      user.roles = permissions;
      setUser(user);

      await router.invalidate();
      navigate({ to: next_url as keyof typeof router.routesByPath });
    } catch (error) {
      console.error("Auth setup failed:", error);
      // Optionally redirect to login or show error message
      navigate({ to: "/login" });
    } finally {
      isAuthenticating.current = false;
    }
  }, [search.tokens, search.additional, search.next_url, setTokens, setUser, router, navigate]);

  useEffect(() => {
    handleAuth();
  }, [handleAuth]);

  return <SplashPage />;
}
